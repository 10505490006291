<template>
  <div class="formdata" style="padding: 20px">
    <el-form ref="form" :model="form" :rules="rules" label-width="120px">
      <h4 class="title">订单基本信息</h4>
      <el-row>
        <el-col :span="10">
          <el-form-item label="业务订单号 :">
            <span>{{ form.orderNo }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="下单时间 :">
            <span>{{ form.createTime }}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="form.completeTime">
        <el-col :span="10">
          <el-form-item label="结束时间 :">
            <span>{{ form.completeTime }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="服务时长 :">
            <span>{{ queryDuration(form) }}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="问诊方式 :">
            <span>{{ consultationTypeDic[$route.query.type] }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="预约时间段 :">
            <span>
              {{ form.appointment && form.appointment.scheduleDate }}-{{
                form.appointment && form.appointment.startTime
              }}-{{ form.appointment && form.appointment.endTime }}</span
            >
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="订单业务状态 :">
            <span>{{ orderStatusList[form.consultationStatus] }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="就诊时间 :">
            <span>{{ form.serverStartTime }}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="订单价格 :">
            <span>{{ form.appointment.doctorSettlementFee }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="支付价格 :">
            <span>{{ form.appointment.payFee }}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <h4 class="title">医生基本信息</h4>
      <el-row>
        <el-col :span="10">
          <el-form-item label="医生团队名称 :">
            <span>{{ form.doctor.teamName }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="科室名称 :">
            <span>{{ form.doctor.deptName }}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="医生姓名 :">
            <span>{{ form.doctor.doctorName }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="年龄 :">
            <span>{{ getDoctorAge(form.doctor.idCard) }}岁</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="联系方式 :">
            <!-- <span>{{ form.mobile }}</span> -->
            <span v-if="!allPhoneD">{{ form.doctor.mobile | hidePhone }}</span>
            <span v-else>{{ form.doctor.mobile }}</span>
            <i class="el-icon-view" @click="isShow(0)" />
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="医生职称 :">
            <span>{{ form.doctor.professionalTitleName }}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <h4 class="title">患者基本信息</h4>
      <el-row>
        <el-col :span="10">
          <el-form-item label="患者名称 :">
            <span>{{ form.appointment.patientName }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="联系方式 :">
            <!-- <span>{{ form.appointment.patientMobile }}</span> -->
            <span v-if="!allPhoneP">{{
              form.appointment.patientMobile | hidePhone
            }}</span>
            <span v-else>{{ form.appointment.patientMobile }}</span>
            <i class="el-icon-view" @click="isShow(1)" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="性别 :">
            <span>{{ form.appointment.patientSex == 1 ? '男' : '女' }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="年龄 :">
            <span>{{ form.appointment.patientAge }}岁</span>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- <el-row>
        <el-col :span="10">
          <el-form-item label="主诉 :">
            <span>{{ form.appointment.consultationContent }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="现病史 :">
            <span>{{ summaryDetail.presentIllnessHistory }}</span>
          </el-form-item>
        </el-col>
      </el-row> -->
      <h4 class="title">问诊小结</h4>
      <el-row>
        <el-col :span="10">
          <el-form-item label="主诉 :">
            <span>{{ form.appointment.consultationContent }}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="现病史 :">
            <span>{{ summaryDetail.presentIllnessHistory }}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="既往史 :">
            <span>{{ summaryDetail.pastHistory }}</span>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="10">
          <el-form-item label="过敏史 :">
            <span>{{ summaryDetail.allergicHistory }}</span>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="10">
          <el-form-item label="个人史 :">
            <span>{{ summaryDetail.personalHistory }}</span>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="10">
          <el-form-item label="家族史 :">
            <span>{{ summaryDetail.familyHistory }}</span>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="10">
          <el-form-item label="诊断 :">
            <span>{{ summaryDetail.diagnosisName }}</span>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="10">
          <el-form-item label="处理意见 :">
            <span>{{ summaryDetail.handlingSuggestions }}</span>
            <span />
          </el-form-item>
        </el-col>
      </el-row>
      <h4 v-if="form.hasSummary" class="title">诊断基本信息</h4>
      <div class="text-dianosisName">
        {{ summaryDetail.diagnosisName }}
      </div>
      <!-- <div>
        <div
          v-for="(item,index) in summaryDetail.patientPhoto.split(',')"
          :key="index"
        >
          <el-image
            v-if="item"
            style="width:148px ; height: 148px; margin-right: 10px;"
            :src="item"
            fit="scale-down"
            :preview-src-list="!summaryDetail.patientPhoto?[]:summaryDetail.patientPhoto.split(',')"
            @click="solveRefresh()"
          />
        </div>
      </div> -->
      <h4 v-if="form.hasPrescription" class="title">处方信息</h4>
      <div v-if="prescriptionList.length > 0" class="prescription">
        <div v-for="item in prescriptionList" :key="item.consultationOrderNo">
          <el-form-item label="开方时间:">
            {{ item.createTime }}
          </el-form-item>
          <el-form-item label="审方时间:">
            {{ item.auditTime }}
          </el-form-item>
          <el-form-item label="处方订单号:">
            {{ item.id }}
          </el-form-item>
          <el-button
            type="primary"
            class="btn"
            @click="goPrescriptionDetail(item)"
          >
            查看处方详情
          </el-button>
        </div>
      </div>

      <template v-if="form.consultationStatus === 'CANCELED'">
        <h4 class="title">取消原因</h4>
        <el-form-item>
          <span>{{ form.cancelReason }}</span>
        </el-form-item>
      </template>
      <el-form-item>
        <el-button type="primary" @click="$router.go(-1)"> 返回 </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { orderStatusList } from '@/utils/component/publicList';
import { getDuration } from '@/utils/index';
export default {
  name: 'Details',
  data() {
    this.transferPay = {
      WEPAY: '微信',
      ALIPAY: '支付宝',
      OTHER: '其他',
    };
    return {
      allPhoneD: false, // 是否展示完全的电话号码
      allPhoneP: false, // 是否展示完全的电话号码
      consultationTypeDic: JSON.parse(this.$route.query.consultationTypeDic), // 问诊类型字典
      orderStatusList: orderStatusList, // 订单业务状态
      prescriptionList: {}, // 处方信息
      form: {
        answerStatus: '',
        appointment: {
          consultationContent: '',
          consultationImageUrl: '',
          consultationTime: '',
          patientAge: '',
          patientIdCard: '',
          patientMobile: '',
          patientName: '',
          patientSex: '',
          resourceNo: '',
        },
        doctor: {
          deptCode: '',
          deptName: '',
          doctorName: '',
          orgCode: '',
          teamCode: '',
          idCard: '',
          teamName: '',
          mobile: '',
        },
      },
      rules: {},
      summaryDetail: {}, // 问诊小结诊断信息
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.getOrderDetail();
  },
  methods: {
    ...mapActions('orderManage', ['queryDetail']),
    // 电话号码显示隐藏切换
    isShow(index) {
      if (index === 0) {
        this.allPhoneD = !this.allPhoneD;
      } else {
        this.allPhoneP = !this.allPhoneP;
      }
    },
    // 获取医生年龄
    getDoctorAge(UUserCard) {
      console.log(UUserCard, 'izx');
      var myDate = new Date();
      var month = myDate.getMonth() + 1;
      var day = myDate.getDate();
      var age = myDate.getFullYear() - UUserCard.substring(6, 10) - 1;
      if (
        UUserCard.substring(10, 12) < month ||
        (UUserCard.substring(10, 12) === month &&
          UUserCard.substring(12, 14) <= day)
      ) {
        age++;
      }
      return age;

      // 获取年龄
    },
    // 订单详情
    getOrderDetail() {
      const param = {
        orderNo: this.$route.query.orderDetailNum,
      };
      this.$api.videoQueryDetail(param).then((res) => {
        // this.form = res.data
        if (res.code === 0) {
          this.form = res.data;
          console.log('9999999999999999999999999', res.data);

          if (res.data.hasSummary) {
            this.querySummary(res.data.orderNo);
          }
          if (res.data.hasPrescription) {
            this.queryPrescriptionDetail(res.data.orderNo);
          }
        }
        return;
      });
    },
    // el-image点击图片页面卡顿问题
    solveRefresh() {
      document.body.style = '';
    },
    // 查询问诊小结
    querySummary(orderNo) {
      const param = {
        consultationType: this.$route.query.type,
        consultationOrderNo: orderNo,
      };
      this.$api.summaryDetail(param).then((res) => {
        this.summaryDetail = res.data;
        console.log('888888888888888888888888888888888888888888', res.data);
      });
    },
    // 查询处方详情
    queryPrescriptionDetail(orderNo) {
      const param = {
        consultationOrderNo: orderNo,
        consultationType: this.$route.query.type,
      };
      this.$api.prescriptionDetail(param).then((res) => {
        this.prescriptionList = res.data;
      });
    },
    // 跳转到处方详情
    goPrescriptionDetail(item) {
      this.$router.push({
        path: '/doctorManage/viewPrescriptionDetail',
        query: {
          prescriptionDetailData: JSON.stringify(item),
        },
      });
    },
    // 获取时长
    queryDuration(row) {
      const { serverStartTime, completeTime } = row;
      return getDuration(serverStartTime, completeTime);
    },
  },
};
</script>

<style lang="scss">
// 预约时间的可预约样式
</style>
<style lang="scss" scoped>
.formdata {
  label {
    font-size: 14px;
    color: #606266;
  }

  .input_el {
    width: 30%;
  }
}
.labelStyle {
  margin-right: 10px;
}

.serviceDetail {
  font-size: 16px;
  color: red;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.orderstatus {
  padding: 10px 5px;
  margin-left: 40px;
  border: 1px solid black;
}

.title {
  height: 40px;
  margin-bottom: 10px;
  line-height: 40px;
  text-align: center;
  background-color: #fff;
}
.imgstyle {
  display: flex;
  //  justify-content:space-between;
}
.prescription {
  display: flex;
  justify-content: space-between;
}
.text-dianosisName {
  margin: 30px 50px;
}
</style>
